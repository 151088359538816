import React from "react"

const Jumotron = () => {
  return (
    <>
      <section className="jumbotron text-center">
        <div className="container">
          <p className="lead text-muted">
            Passionate about growing business, and do this through our own
            highly experienced and capable consultants. By systematically
            assessing the current state of our client’s business processes, our
            Business Consulting team identify areas of weakness and work with
            clients to prioritise improvement opportunities and plan
            implementation.
          </p>
          <p>
            <a href="/about" className="btn btn-primary localbutton">
              More
            </a>
          </p>
        </div>
      </section>
    </>
  )
}

export default Jumotron
