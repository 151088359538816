import React from 'react';

const SingleCard = ({fontawsome='fas fa-chart-pie', text='This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.'}) => {
  var style={
    fontSize: '60px'
  }
  var fontStyle={
    textAlign:'center'
  }
    return ( 
        <>
        <div className="col-md-4">
              <div className="card mb-4 box-shadow">
              <div style={fontStyle}>
              <i className={fontawsome} style={style}></i>
              </div>
                <div className="card-body">
    <p className="card-text">{text}</p>
                </div>
              </div>
              </div>
        </>
     );
}
 
export default SingleCard;