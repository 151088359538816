import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Jumotron from "../components/jumbotron"
import Card from "../components/card"
import Button from "../components/button"

const description =
  "Pomegenix Solutions is a team of designers, developers, and engineers that involves in UX design, software development, and team integration for web, mobile apps, and enterprise systems."

const info ='Good thoughts. Good deeds. Broad visions'
const cloud = `The future of cloud computing will most likely represent a combination of cloud based software products and on premises compute to create a hybrid IT solution that balances the scalability and flexibility associated with cloud and the security and control of a private data center.`
const agile=`Our agility is concerned with the adoption of the evolution of values, behaviours and capabilities. These enable businesses and individuals to be more adaptive, creative and resilient when dealing with complexity, uncertainty and change leading to improved well-being and better outcomes.`
const business =`Implementing an effective digital strategy across front-, middle- and back-office IT environments is crucial to staying relevant. Yet, the urgent need to create and act on a digital strategy gives rise to a host of complex problems that enterprises must sort out quickly—or risk falling behind.`
const IndexPage = () => (
  <Layout>
    <Hero
      text="Consulting at its best"
      button={<Button text="More About us" to="/about" />}
      info={info}
    />
    <SEO
      title="Business and Technology Consulting"
      description={description}
      keywords={[
        `Pomegenix`,
        `Digital`,
        `Business`,
        `Solutions`,
        `Technology`,
        `Software`,
      ]}
    />
    <Jumotron />
    <div className="container">
      <div className="row">
        <Card fontawsome="fas fa-cloud" text={cloud}/>
        <Card fontawsome="fas fa-cogs" text={agile}/>
        <Card fontawsome="fas fa-business-time" text={business} />
      </div>
    </div>
  </Layout>
)

export default IndexPage
