import React from "react"

const Button = ({ text, to }) => {
  return (
    <>
      <a
        className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
        href={to}
      >
        {text}
      </a>
    </>
  )
}

export default Button
